import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import axios from '../axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../state/user';
import { RootState } from '../state/store';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const SigninContainer = styled.div<{}>`
  /* display: flex;
  justify-content: center; */
  html {
    height: 100%;
  }

  h2 {
    font-size: larger;
  }

  /* signin */
  .signin-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }

  .signin-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
  }

  .signin-box .user-box {
    position: relative;
  }

  .signin-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
  }
  .signin-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: 0.5s;
  }

  .signin-box .user-box input:focus ~ label,
  .signin-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .signin-box a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 40px;
    letter-spacing: 4px;
  }

  /* signup  */
  .signup-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }

  .signup-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
  }

  .signup-box .user-box {
    position: relative;
  }

  .signup-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
  }
  .signup-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: 0.5s;
  }

  .signup-box .user-box input:focus ~ label,
  .signup-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .signup-box a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: 0.5s;
    margin-top: 40px;
    letter-spacing: 4px;
  }

  .button-box {
    display: flex;
    justify-content: space-between;
    > div {
      padding: 0.5rem;
      border-radius: 5px;
      background-color: white;
      :hover {
        background-color: black;
        color: #fff;
      }
    }
  }

  @media (max-width: 500px) {
    .signin-box {
      width: 90%;
    }

    .signup-box {
      width: 90%;
      padding: 5%;
    }
  }
`;

function Signin() {
  const dispatch = useDispatch();
  const [state, setState] = useState(true);
  const [signinData, setSigninData] = useState({});
  const [signupData, setSignupData] = useState({});

  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (user.name !== 'guest') {
      navigate('/', { replace: true });
    }
  });

  const signinInputDataHandler = (data: object) => {
    // console.log('signinInputDataHandler', data);
    setSigninData(data);
  };

  const signupInputDataHandler = (data: object) => {
    console.log(data);
    setSignupData(data);
  };

  const signinHandler = async (data: object) => {
    // console.log('signinHandler', data);
    // *************** this will be https and into env ***********************

    await axios
      .post('/users/signin', data, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        console.log(response);
        if (!response.data.resultData.message) {
          dispatch(userActions.change({ name: 'guest' }));
          alert("Please check your ID and password again.");
          <Link to="/signin"></Link>;
          return;
        }
        dispatch(userActions.change(response.data.resultData.user));
        dispatch(userActions.auth(dayjs().utc().format('YYYY-MM-DD HH:mm:ss')));
      })
      .catch((response) => {
        // console.log('Error!', response);
      });
  };

  const signupHandler = async (data: object) => {
    console.log('signupHandler', data);

    if (Object.keys(data).length === 5) {
      console.log(Object.keys(data).length);
      await axios
        .post('/users/join', data, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        .then((response) => {
          // console.log(response);
          // console.log(response.data);
          // console.log(response.data.status_code);

          if (response.data.status_code === 200) {
            alert('Thanks for join us! Please sign in.');
            window.location.reload();
          } else {
            alert('Oops! something is wrong.');
          }
        })
        .catch((response) => {
          // console.log('Error!', response);
        });
    } else {
      alert('Please fill up the form.');
    }
  };

  const signinColletcingDataGroup = [
    {
      key: 1,
      title: 'Username',
      label: 'id',
      type: 'text',
      unit: '',
      name: '',
      value: '',
    },
    {
      key: 2,
      title: 'Password',
      label: 'pw',
      type: 'password',
      unit: '',
      name: '',
      value: '',
    },
  ];

  const signupColletcingDataGroup = [
    {
      key: 1,
      title: 'Username',
      label: 'id',
      type: 'text',
      unit: '',
      name: '',
      value: '',
    },
    {
      key: 2,
      title: 'Password',
      label: 'pw',
      type: 'password',
      unit: '',
      name: '',
      value: '',
    },
    {
      key: 3,
      title: 'Password Confirm',
      label: 'pw confirm',
      type: 'password',
      unit: '',
      name: '',
      value: '',
    },
    {
      key: 4,
      title: 'Name',
      label: 'name',
      type: 'text',
      unit: '',
      name: '',
      value: '',
    },
    {
      key: 5,
      title: 'Date of Birth',
      label: 'dob',
      type: 'date',
      unit: '',
      name: '',
      value: '',
    },
    // {
    //   key: 6,
    //   title: 'Unit',
    //   label: 'unit',
    //   type: 'select',
    //   unit: '',
    //   name: '',
    //   value: '',
    // },
    // {
    //   key: 7,
    //   title: 'Height',
    //   label: 'height',
    //   type: 'number',
    //   unit: 'cm',
    //   name: '',
    //   value: '',
    // },
    // {
    //   key: 8,
    //   title: 'Weight',
    //   label: 'weight',
    //   type: 'number',
    //   unit: 'kg',
    //   name: '',
    //   value: '',
    // },
  ];

  return (
    <SigninContainer>
      <Header />
      {state ? (
        <div className="signin-box">
          <h2>Sign in</h2>
          {signinColletcingDataGroup.map((data) => (
            <div className="user-box" key={data.key}>
              <input
                onChange={(e) =>
                  signinInputDataHandler({
                    ...signinData,
                    [data.label]: e.target.value,
                  })
                }
                type={data.type}
                name=""
                required
              />
              <label>{data.title}</label>
            </div>
          ))}
          <div className="button-box">
            <div className="cp" onClick={() => setState(!state)}>
              Sign up
            </div>
            <div className="cp" onClick={() => signinHandler(signinData)}>
              Sign in
            </div>
          </div>
        </div>
      ) : (
        <div className="signup-box">
          <h2 className="cp">Sign up</h2>
          {signupColletcingDataGroup.map((data) => (
            <div className="user-box" key={data.key}>
              <input
                onChange={(e) =>
                  signupInputDataHandler({
                    ...signupData,
                    [data.label]: e.target.value,
                  })
                }
                type={data.type}
                name=""
                required
              />
              <label>{data.title}</label>
            </div>
          ))}
          <div className="button-box">
            <div className="cp" onClick={() => setState(!state)}>
              Sign in
            </div>
            <div
              className="cp"
              onClick={() => {
                signupHandler(signupData);
              }}
            >
              Sign up
            </div>
          </div>
        </div>
      )}
    </SigninContainer>
  );
}

export default Signin;
